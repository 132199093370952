import { createPortal } from 'react-dom';

import { Transaction } from 'components/Transaction/Transaction';

import { useSelector } from 'react-redux';
import { selectDebt } from 'redux/selectors';
import {
  ModalBackdrop,
  ModalContainer,
} from '../ModalFirstEnter/ModalFirstEnter.styled';
import useEscKeyHandler from 'hooks/useEscKeyHandler';

const ModalPaidStatus = ({
  onClose,
  handleBackdropClick,
  debt,
  overDueType,
}) => {
  const debts = useSelector(selectDebt);

  const modalRoot = document.querySelector('#modal-root');

  const currentDebt = debts.find(item => item._id === debt._id);

  const paymentByMonth = currentDebt.paymentsByMonth;
  useEscKeyHandler(onClose);
  const addIconFieldDebt = arr => {
    return arr.map(obj => {
      const { amount, ...rest } = obj;
      const icon = () => {
        // eslint-disable-next-line default-case
        switch (obj.paid) {
          case null:
            return 'icon-empty';
          case true:
            return 'icon-greenCross';
          case false:
            return 'icon-redross';
        }
      };
      return {
        ...rest,
        sum: amount,
        icon: icon(),
      };
    });
  };

  const handleBackdropClickPaid = evt => {
    if (evt.target === evt.currentTarget) {
      onClose();
    }
  };

  return createPortal(
    <ModalBackdrop onClick={handleBackdropClickPaid} style={{ zIndex: '100' }}>
      <ModalContainer
        style={{
          padding: '0',
          backgroundColor: 'white',
          maxWidth: '100%',
        }}
      >
        <Transaction
          onClick={handleBackdropClick}
          transactions={addIconFieldDebt(paymentByMonth)}
          allTransactions={false}
          tableHeadingSelect={false}
          deleteType={'debt'}
          paidStatusMarker={true}
          debtParent={currentDebt}
          iconDebt={currentDebt.icon}
          onClose={onClose}
          overDueType={true}
          modalPaidStatus={true}
        />
      </ModalContainer>
    </ModalBackdrop>,
    modalRoot
  );
};

export default ModalPaidStatus;
