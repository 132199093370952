import styled from 'styled-components';
import oops404Error from '../../images/oops404Error.svg';

export const Container = styled.div`
  position: relative;
  height: 100vh;
  background-image: url('${oops404Error}');
  background-repeat: no-repeat;
  background-position: 50% 40px;
  background-size: scale-down;
`;

export const TextLink = styled.p`
  display: block;
  font-family: 'Kulim Park';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: ${p => p.theme.colors.textprimary};
  position: absolute;
  bottom: 64px;
`;

export const Link = styled.button`
  display: inline;
  font-family: 'Kulim Park';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  background-color: transparent;
  border: none;
  color: ${p => p.theme.colors.accent};
  text-decoration-line: underline;
  cursor: pointer;
`;
