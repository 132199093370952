import { useEffect } from 'react';

const useEscKeyHandler = onClose => {
  useEffect(() => {
    const handleKeyDown = evt => {
      if (evt.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);
};

export default useEscKeyHandler;
