/* eslint-disable react-hooks/exhaustive-deps */

import { useSelector } from 'react-redux';
import { useState } from 'react';
import { CalendarReport } from 'components/Calendar/CalendarReport';

import { Reminders } from 'components/Reminders/Reminders';
import {
  selectOperationType,
  selectReminders,
  selectDebt,
} from 'redux/selectors';

import { Transaction } from 'components/Transaction/Transaction';

import { Debts } from 'components/Planning/Debts';
import { theme } from 'utils/constants';

import { useGetTransactions } from 'hooks/useGetTransactions';
import { useResetActiveIndex } from 'hooks/useResetActiveIndex';

import { useGetDebts } from 'hooks/useGetDebts';
import { useGetRemainders } from 'hooks/useGetRemainders';

export const addIconField = arr => {
  return arr.map(obj => {
    const { debt, amount, ...rest } = obj;

    const icon = debt !== 'I owe' ? 'icon-Im-owed' : 'icon-I-owe';
    return {
      ...rest,
      sum: amount,
      icon: obj.hasOwnProperty('icon') ? obj.icon : icon,
    };
  });
};

export const sortedTransactionsAll = data =>
  [...data].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
const Planning = ({
  dateRange,
  setDateRange,
  title,
  setTitle,
  setActiveIndex,
  activeIndex,
}) => {
  const [startDate, endDate] = dateRange;
  const [modalDebtEditOpen] = useState(false);
  const { operation, reminders, debts } = useSelector(state => ({
    operation: selectOperationType(state),
    reminders: selectReminders(state),
    debts: selectDebt(state),
  }));

  useResetActiveIndex(setActiveIndex, activeIndex);
  useGetTransactions(operation, dateRange);
  useGetDebts(dateRange);
  useGetRemainders(dateRange);
  return (
    <>
      <CalendarReport
        dateRange={[startDate, endDate]}
        setDateRange={setDateRange}
        title={title}
        setTitle={setTitle}
      />

      <Reminders />
      <Transaction
        colors={theme.colors.main.orangeMain}
        transactions={sortedTransactionsAll(reminders)}
        allTransactions={false}
        tableHeadingSelect={true}
        deleteType="reminder"
      />
      <Debts modalDebtEditOpen={modalDebtEditOpen} />
      <Transaction
        transactions={sortedTransactionsAll(addIconField(debts))}
        allTransactions={false}
        tableHeadingSelect={false}
        deleteType={'debt'}
        paidStatusMarker={false}
      />
    </>
  );
};

export default Planning;
