import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { logOut } from 'redux/auth/authOperations';
import { useNavigate } from 'react-router-dom';

export const useLogout = handleToggleMenu => {
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleModal = () => {
    setModalOpen(prevState => !prevState);
    handleToggleMenu();
  };

  const handleClick = async () => {
    await dispatch(logOut());
    toggleModal();
    navigate('/');
  };

  return { modalOpen, toggleModal, handleClick, setModalOpen };
};
