import { useDispatch, useSelector } from 'react-redux';
import { selectDebt, selectUserCurrency } from 'redux/selectors';
import { theme } from 'utils/constants';
import { PayDate } from './DebtsUniversalComponent';
import {
  CategoryStyle,
  DescriptionStyle,
  WrapperText,
  WrapSvgText,
  Sum,
  DateTransaction,
} from './UniversalTransactions.styled';
import { EditDeletBtns } from 'components/Modal/ModalTransaction/EditDelBtns/EditDeletBtns';
import { payDebt } from 'redux/debt/debtOperations';
import { Notify } from 'notiflix';
import { useTranslate } from 'hooks/useTranslate';
import { useState } from 'react';
import { PaymentLines } from 'components/Modal/ModalDebt/DebtSetup';
import { getWallets } from 'redux/wallets/walletsOperations';

export const style = {
  color: theme.colors.text.text,

  fontWeight: 700,
  lineHeight: '150%',
  letterSpacing: '4%',
  textAlign: 'left',
};
export const DebtsItemUniversal = ({
  transaction,
  Icons,
  deleteType,
  color,
  position,
  selectedTransactionId,
  handleEditClick,
  tableHeadingSelect,
  paidStatusMarker,
  onClose,
}) => {
  const currencySymbol = useSelector(selectUserCurrency);
  const debts = useSelector(selectDebt);
  const [debtsState, setDebtsState] = useState(debts);
  const dispatch = useDispatch();
  const { monthTrsl, daysTrsl } = useTranslate();

  const handleDebtClick = async (paymentId, debts, paidStatus) => {
    if (!Array.isArray(debts)) {
      return [];
    }

    for (let debt of debts) {
      const paymentIndex = debt.paymentsByMonth.findIndex(
        payment => payment._id === paymentId
      );
      if (paymentIndex !== -1) {
        const updatedPayments = [...debt.paymentsByMonth];
        updatedPayments[paymentIndex] = {
          ...updatedPayments[paymentIndex],
          paid: paidStatus,
        };
        const updatedDebt = { ...debt, paymentsByMonth: updatedPayments };

        try {
          const { _id } = updatedDebt;
          const inPayDebt = {
            debtId: _id,
            paymentId: paymentId,
            amount: updatedPayments[paymentIndex].amount,
            date: updatedPayments[paymentIndex].date,
            paid: paidStatus,
          };
          await dispatch(payDebt(inPayDebt));
          await dispatch(getWallets());
          Notify.success('Статус оплаты изменен');
          setDebtsState(debts);
          onClose();
        } catch (error) {
          console.log('error', error);
        }
      }
    }
    return null;
  };
  const date = transaction => {
    const dateObj = new Date(transaction.date);
    const month = dateObj.toLocaleDateString('en-US', { month: 'long' });
    const translatedMonth = monthTrsl[month];
    const translatedDayOfWeek =
      daysTrsl[dateObj.toLocaleDateString('en-US', { weekday: 'long' })];
    const dayOfMonth = dateObj.getDate();
    const fullDate = dateObj.toLocaleDateString('en-US', {
      year: 'numeric',
    });
    return `${translatedDayOfWeek}, ${dayOfMonth}, ${translatedMonth},  ${fullDate}`;
  };

  return paidStatusMarker ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <WrapSvgText>
        <svg width={32} height={32}>
          <use href={`${Icons}#${transaction.icon}`} />
        </svg>

        <DateTransaction style={{ marginLeft: 16, fontSize: 16 }}>
          <p>Pay Date</p>: {date(transaction)}
        </DateTransaction>
      </WrapSvgText>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Sum style={{ fontSize: 16, color: color }}>
          {currencySymbol} {transaction.sum}
        </Sum>
      </div>
      {selectedTransactionId === transaction._id && (
        <EditDeletBtns
          data={transaction}
          transactionId={transaction._id}
          selectedTransactionId={selectedTransactionId}
          handleEditClick={() => handleDebtClick(transaction._id, debts, true)}
          handleSkipClick={() => handleDebtClick(transaction._id, debts, false)}
          handleNullClick={() => handleDebtClick(transaction._id, debts, null)}
          tableHeadingSelect={tableHeadingSelect}
          deleteType={deleteType}
          paidStatusMarker={paidStatusMarker}
          debts={debtsState}
        />
      )}
    </div>
  ) : (
    <>
      <WrapSvgText>
        <svg width={24} height={24}>
          <use href={`${Icons}#${transaction.icon}`} />
        </svg>

        <WrapperText deleteType={deleteType}>
          <CategoryStyle
            style={{
              ...style,
              fontSize: 12,
            }}
            text={transaction.name}
            length={14}
          />

          <DescriptionStyle
            style={{
              ...style,
              fontSize: 10,
              fontWeight: 400,
            }}
            text={
              transaction.paymentsByMonth &&
              transaction.paymentsByMonth.length > 0
                ? 'Pay by ' +
                  transaction.paymentsByMonth.length.toString() +
                  ' ' +
                  'months'
                : transaction.description
            }
            length={20}
          />
          {transaction.operation === undefined ? (
            <div>
              <PayDate
                payDate={transaction.date}
                datePayments={
                  !transaction.paymentsByMonth ||
                  transaction.paymentsByMonth.length === 0
                    ? 0
                    : transaction.paymentsByMonth.map(payment => payment.date)
                }
                position={position}
                currencySymbol={currencySymbol}
                transactionSum={
                  transaction.sum ? transaction.sum.toFixed(0) : '0'
                }
                colorSum={color}
              />

              <PaymentLines
                paymentsByMonth={transaction.paymentsByMonth}
                payments={transaction.paymentsByMonth}
                datePayments={
                  !transaction.paymentsByMonth ||
                  !transaction.paymentsByMonth.length === 0
                    ? 0
                    : transaction.paymentsByMonth.map(payment => payment.date)
                }
                paid={
                  !transaction.paymentsByMonth ||
                  transaction.paymentsByMonth.length === 0
                    ? 0
                    : transaction.paymentsByMonth.map(el => el.paid)
                }
                paidStatusMarker={paidStatusMarker}
              />
            </div>
          ) : (
            ''
          )}
        </WrapperText>
      </WrapSvgText>

      {selectedTransactionId === transaction._id && (
        <EditDeletBtns
          data={transaction}
          transactionId={transaction._id}
          selectedTransactionId={selectedTransactionId}
          handleEditClick={handleEditClick}
          tableHeadingSelect={tableHeadingSelect}
          deleteType={deleteType}
        />
      )}
    </>
  );
};
