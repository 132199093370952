import { theme } from 'utils/constants';
import { Sum } from './UniversalTransactions.styled';

export const PayDate = ({
  payDate,
  datePayments,

  position,
  currencySymbol,
  transactionSum,
  colorSum,
}) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  let isOverdue = false;
  let date;

  if (datePayments && datePayments.length > 0) {
    const sortedDates = [...datePayments].sort(
      (a, b) => new Date(a) - new Date(b)
    );
    const nearestDate = new Date(sortedDates[0]);
    nearestDate.setHours(0, 0, 0, 0);

    isOverdue = nearestDate < today;

    date = nearestDate.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  } else {
    const startDate = new Date(payDate);
    const nextMonthDate = new Date(
      startDate.setMonth(startDate.getMonth() + 1)
    );

    date = nextMonthDate.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'start' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p
            style={{
              color: isOverdue ? 'red' : '#FF7601',

              fontSize: 10,
              fontWeight: 400,
              lineHeight: '150%',
              letterSpacing: '4%',
              textAlign: 'left',
              marginLeft: 0,
              marginRight: 8,
            }}
          >
            Before
          </p>

          <p
            style={{
              fontSize: 10,
              color: theme.colors.text.text,
              fontWeight: 400,
              lineHeight: '150%',
              letterSpacing: '4%',
              textAlign: 'left',
            }}
          >
            {date}
          </p>
        </div>
        <Sum
          style={{
            color: colorSum,
            marginLeft: 'auto',
          }}
        >
          {position} {currencySymbol} {transactionSum}
        </Sum>
      </div>
    </>
  );
};
