import { TextLink, Container, Link } from 'pages/Page404/Page404.styled';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Page404 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClickHome = () => navigate('/home');

  return (
    <>
      <Container>
        <TextLink style={{ marginBottom: 48 }}>
          {t('page404.textLinkTree')}
          <Link type="button" onClick={handleClickHome}>
            {t('page404.textLinkFour')}
          </Link>
        </TextLink>
      </Container>
    </>
  );
};
export default Page404;
