import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoading, selectUserCurrency } from 'redux/selectors';
import { Loader } from 'components/Loader/Loader';
import {
  Line,
  OverDueTitle,
  Scrollbar,
  TransactionContainer,
  TransactionWrapper,
  WrapSearch,
} from './Transaction.styled';
import { UniversalTransactions } from './UniversalTransactions/UniversalTransactions';
import { Search } from 'components/Calendar/Search';
import FooterSvg from 'images/allSvgFinTrack.svg';

import { useTranslation } from 'react-i18next';

import { SearchComponentPaidStatus } from 'components/Modal/ModalDebt/SearchComponentPaidStatus';

export const filteredTransactions = (data, searchValue) => {
  return data.filter(item => {
    const descriptionMatch =
      item && item.description
        ? item.description.toLowerCase().includes(searchValue.toLowerCase())
        : false;

    const sumMatch =
      item && item.sum && item.sum.toString().includes(searchValue);

    const categoryMatch = item.icon
      .toLowerCase()
      .includes(searchValue.toLowerCase());

    return descriptionMatch || sumMatch || categoryMatch;
  });
};

export const Transaction = ({
  transactions,
  allTransactions,
  tableHeadingSelect,
  deleteType,
  isWalletView,
  setModalDebtEditOpen,
  modalDebtEditOpen,
  overDueType,
  setIsOverdue,
  paidStatusMarker,
  iconDebt,
  debtParent,

  modalPaidStatus,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const [isBlurApplied, setIsBlurApplied] = useState(false);

  const isLoading = useSelector(selectIsLoading);
  const currencySymbol = useSelector(selectUserCurrency);
  const { t } = useTranslation();
  const divRef = useRef(null);

  const debtType = iconDebt => {
    if (iconDebt === 'icon-Im-owed') {
      return t('modalDebt.imOwed');
    }
    return t('modalDebt.iOwe');
  };
  const getColorAndIcon = operation => {
    if (operation === 'expense') {
      return {
        color: '#FF1717',
        icon: `${FooterSvg}#icon-I-owe`,
        position: '-',
      };
    }
    if (operation === 'income') {
      return {
        color: '#01AB3A',
        icon: `${FooterSvg}#icon-Im-owed`,
        position: '+',
      };
    }
    if (operation === undefined) {
      return {
        color: '#FF7601',
        position: null,
      };
    }

    return {
      color: '#000000',
      icon: '',
    };
  };

  useEffect(() => {
    const divElement = divRef.current;
    const handleClick = event => {
      if (divElement.contains(event.target)) {
        setSelectedTransactionId(null);
        setIsBlurApplied(false);
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [divRef, setSelectedTransactionId, setIsBlurApplied]);

  if (isWalletView) {
    return (
      <TransactionContainer
        ref={divRef}
        isWalletView={isWalletView}
        overDueType={overDueType}
        deleteType={deleteType}
      >
        <Line></Line>
        <TransactionWrapper>
          {isLoading ? (
            <Loader />
          ) : (
            <Scrollbar isTransactionSelected={selectedTransactionId !== null}>
              <UniversalTransactions
                filteredTransactions={transactions}
                getColorAndIcon={getColorAndIcon}
                setSelectedTransactionId={setSelectedTransactionId}
                selectedTransactionId={selectedTransactionId}
                transactions={transactions}
                isBlurApplied={isBlurApplied}
                setIsBlurApplied={setIsBlurApplied}
                allTransactions={allTransactions}
                tableHeadingSelect={tableHeadingSelect}
                deleteType={deleteType}
                setModalDebtEditOpen={setModalDebtEditOpen}
                modalDebtEditOpen={modalDebtEditOpen}
                overDueType={overDueType}
                setIsOverdue={setIsOverdue}
                paidStatusMarker={paidStatusMarker}
                modalPaidStatus={modalPaidStatus}
              />
            </Scrollbar>
          )}
        </TransactionWrapper>
      </TransactionContainer>
    );
  }
  return isLoading && paidStatusMarker ? (
    ''
  ) : overDueType && isLoading ? (
    ''
  ) : (
    <TransactionContainer overDueType={overDueType} ref={divRef}>
      {paidStatusMarker ? (
        isLoading ? (
          ''
        ) : (
          <WrapSearch>
            <SearchComponentPaidStatus
              iconDebt={iconDebt}
              debtType={debtType}
              debtParent={debtParent}
              getColorAndIcon={getColorAndIcon}
              currencySymbol={currencySymbol}
            />
          </WrapSearch>
        )
      ) : overDueType ? (
        isLoading ? (
          ''
        ) : (
          <WrapSearch>
            <OverDueTitle>Overdue debts</OverDueTitle>
          </WrapSearch>
        )
      ) : (
        <WrapSearch>
          <Search searchValue={searchValue} setSearchValue={setSearchValue} />
        </WrapSearch>
      )}

      <Line></Line>
      <TransactionWrapper>
        {isLoading ? (
          overDueType ? (
            ''
          ) : (
            <Loader />
          )
        ) : (
          <Scrollbar isTransactionSelected={selectedTransactionId !== null}>
            <UniversalTransactions
              filteredTransactions={filteredTransactions(
                transactions,
                searchValue
              )}
              getColorAndIcon={getColorAndIcon}
              setSelectedTransactionId={setSelectedTransactionId}
              selectedTransactionId={selectedTransactionId}
              transactions={transactions}
              isBlurApplied={isBlurApplied}
              setIsBlurApplied={setIsBlurApplied}
              allTransactions={allTransactions}
              tableHeadingSelect={tableHeadingSelect}
              deleteType={deleteType}
              setModalDebtEditOpen={setModalDebtEditOpen}
              modalDebtEditOpen={modalDebtEditOpen}
              overDueType={overDueType}
              paidStatusMarker={paidStatusMarker}
            />
          </Scrollbar>
        )}
      </TransactionWrapper>
    </TransactionContainer>
  );
};
