import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getDebt } from 'redux/debt/debtOperations';

export const useGetDebts = dateRange => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDebt({ dateRange })).catch(error => {
      console.error('Failed to get debt:', error);
    });
  }, [dateRange, dispatch]);
};
