import { createContext, useState } from 'react';

import { createPortal } from 'react-dom';

import {
  ModalBackdrop,
  ModalContainer,
} from '../ModalFirstEnter/ModalFirstEnter.styled';

import { Transaction } from 'components/Transaction/Transaction';

import { addIconField, sortedTransactionsAll } from 'pages/Planning/Planning';
import useEscKeyHandler from 'hooks/useEscKeyHandler';

const modalRoot = document.querySelector('#modal-root');
export const HasRunContext = createContext(false);

export const HasRunProvider = ({ children }) => {
  const [hasRun, setHasRun] = useState(false);

  return (
    <HasRunContext.Provider value={{ hasRun, setHasRun }}>
      {children}
    </HasRunContext.Provider>
  );
};
export const ModalOverduePayment = ({ onClose, debts, isLoading }) => {
  const handleBackdropClick = evt => {
    if (evt.target === evt.currentTarget) {
      onClose();
    }
  };

  useEscKeyHandler(onClose);

  return (
    !isLoading &&
    createPortal(
      <ModalBackdrop
        style={{
          zIndex: '100',
        }}
        onClick={handleBackdropClick}
      >
        <ModalContainer style={{ padding: 0 }}>
          <Transaction
            transactions={sortedTransactionsAll(addIconField(debts))}
            allTransactions={false}
            tableHeadingSelect={false}
            deleteType={'debt'}
            overDueType={true}
          />
        </ModalContainer>
      </ModalBackdrop>,
      modalRoot
    )
  );
};
