import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getReminders } from 'redux/reminders/remindersOperations';

export const useGetRemainders = dateRange => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReminders({ dateRange })).catch(error => {
      console.error('Failed to get reminders:', error);
    });
  }, [dateRange, dispatch]);
};
