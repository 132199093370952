import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Header } from 'components/Header/Header';
import { Cont } from './Container/Container.styled';
import AuthPage from 'pages/Auth/Auth';
import { useDispatch } from 'react-redux';
import { RefreshUser } from 'redux/auth/authOperations';
import { ModalPassRecovery } from 'components/Modal/ModalPassRecovery/ModalPassRecovery';
import { RestrictedRoute } from './RestrictedRoute';
import { PrivateRoute } from './PrivateRoute';
import { HasRunProvider } from './Modal/ModalDebt/ModalOverduePayment';
import Page404 from 'pages/Page404/Page404';
import Footer from './Footer/Footer';

const Home = lazy(() => import('pages/Home/Home'));
const Reports = lazy(() => import('pages/Reports/Reports'));
const WalletsPage = lazy(() => import('pages/Wallet/Wallets'));
const Planning = lazy(() => import('pages/Planning/Planning'));
const BurgerAccount = lazy(() => import('./Header/BurgerAccount/BurgerAcount'));


export const App = () => {
  const [date, setDate] = useState(new Date());
  const [dateRange, setDateRange] = useState([null, null]);
  const [title, setTitle] = useState(1);
  const [activeIndex, setActiveIndex] = useState(-1);

  const [emailForResend, setEmailForResend] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RefreshUser());
  }, [dispatch]);

  const loc = useLocation();
  useEffect(() => {
    localStorage.setItem('path', loc.pathname);
  }, [loc.pathname]);

  return (
    <HasRunProvider>
      <>
        <Header activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
        <Cont>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route
                path="/"
                element={
                  <RestrictedRoute
                    component={
                      <AuthPage
                        emailForResend={emailForResend}
                        setEmailForResend={setEmailForResend}
                      />
                    }
                  />
                }
              />
              <Route
                path="/home"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    component={
                      <Home
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        date={date}
                        setDate={setDate}
                        title={title}
                        setTitle={setTitle}
                        setActiveIndex={setActiveIndex}
                        activeIndex={activeIndex}
                      />
                    }
                  />
                }
              />
              <Route
                path="/reports"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    component={
                      <Reports
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        title={title}
                        setTitle={setTitle}
                        setActiveIndex={setActiveIndex}
                        activeIndex={activeIndex}
                      />
                    }
                  />
                }
              />
              <Route
                path="/wallets"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    component={
                      <WalletsPage
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        title={title}
                        setTitle={setTitle}
                        setActiveIndex={setActiveIndex}
                        activeIndex={activeIndex}
                      />
                    }
                  />
                }
              />
              <Route
                path="/planning"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    component={
                      <Planning
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        title={title}
                        setTitle={setTitle}
                        setActiveIndex={setActiveIndex}
                        activeIndex={activeIndex}
                      />
                    }
                  />
                }
              />
              <Route
                path="/personal-data"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    component={
                      <BurgerAccount
                        setActiveIndex={setActiveIndex}
                        activeIndex={activeIndex}
                      />
                    }
                  />
                }
              />
              <Route
                path="/notifications"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    component={
                      <BurgerAccount
                        setActiveIndex={setActiveIndex}
                        activeIndex={activeIndex}
                      />
                    }
                  />
                }
              />
              <Route
                path="/security"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    component={
                      <BurgerAccount
                        setActiveIndex={setActiveIndex}
                        activeIndex={activeIndex}
                      />
                    }
                  />
                }
              />
              <Route
                path="/contact-support"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    component={
                      <BurgerAccount
                        setActiveIndex={setActiveIndex}
                        activeIndex={activeIndex}
                      />
                    }
                  />
                }
              />
              <Route
                path="/donate"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    component={
                      <BurgerAccount
                        setActiveIndex={setActiveIndex}
                        activeIndex={activeIndex}
                      />
                    }
                  />
                }
              />
              <Route
                path="/auth/modal-password-recovery"
                element={<ModalPassRecovery emailForResend={emailForResend} />}
              />
              <Route path="*" element={<Page404 />} />
            </Routes>
          </Suspense>
        </Cont>
        <Footer />
      </>
    </HasRunProvider>
  );
};
