import {
  ReminderCategory,
  RemindersText,
} from 'components/Reminders/Reminders.styled';
import { Sum } from 'components/Transaction/UniversalTransactions/UniversalTransactions.styled';

import { PaymentLines } from './DebtSetup';
import Icons from 'images/allSvgFinTrack.svg';
export const SearchComponentPaidStatus = ({
  iconDebt,
  debtType,
  debtParent,
  getColorAndIcon,
  currencySymbol,
}) => {
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <svg width={32} height={32}>
              <use href={`${Icons}#${iconDebt}`} />
            </svg>
          </div>
        </div>
        <div style={{ marginLeft: '16px', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <RemindersText style={{ fontSize: '16px' }}>
                  {debtType(iconDebt)}
                </RemindersText>
                <ReminderCategory
                  text={debtParent.name}
                  length={20}
                  style={{ fontSize: '14px' }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 4,
                  marginLeft: 'auto',
                }}
              >
                <Sum
                  style={{
                    display: 'flex',
                    fontSize: '16px',
                    color: getColorAndIcon().color,
                  }}
                >
                  {currencySymbol} {debtParent.amount}
                </Sum>
              </div>
            </div>
          </div>
          <PaymentLines
            paymentsByMonth={debtParent.paymentsByMonth}
            payments={debtParent}
            datePayments={debtParent.paymentsByMonth.map(item => item.date)}
            paid={debtParent.paymentsByMonth.map(item => item.paid)}
          />
        </div>
      </div>
    </>
  );
};
