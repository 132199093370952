import styled from 'styled-components';

export const SwitchDebt = styled.div`
  display: flex;
  border-radius: 30px;
  box-shadow: 2px 4px 20px rgba(170, 178, 197, 0.25);
  margin-bottom: 16px;
`;

export const TextDebt = styled.p`
  margin-left: 16px;
  font-size: 10px;
  line-height: 1.5;
  letter-spacing: 0.04em;
  color: #ff7601;
`;

export const WrapIM = styled.button`
  display: flex;
  align-items: center;
  padding-left: 32px;
  padding-right: 21px;
  border: none;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 30px 0px 0px 30px;
  width: 100%;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.main.orangeLight : theme.colors.main.white};
  &:hover {
    background-color: #fff1e5;
  }
`;

export const WrapI = styled(WrapIM)`
  padding-left: 40px;
  padding-right: 30px;
  border-radius: 0px 30px 30px 0px;
`;

export const RegWrap = styled.div`
  margin-top: 16px;
  border-radius: 30px;
  background-color: #fff1e5;
  padding: 16px;
  margin-right: 24px;
`;

export const AddBtn = styled.button`
  background-color: #ffffff;
  border: 1px solid #fff1e5;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const AddPaymentWrap = styled.div`
  display: flex;
  align-items: center;
`;
export const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const SliderInput = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.main.orangeLight};
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.main.orangeMain};
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4caf50;
    cursor: pointer;
  }
`;

export const SliderValue = styled.div`
  position: absolute;
  top: 25px;
  left: ${props => props.value}%;
  transform: translateX(-50%);
  background: transparent;
  color: ${({ theme }) => theme.colors.main.orangeMain};
  padding: 5px 10px;
  border-radius: 5px;
`;
export const SectorLine = styled.div`
  height: 4px;
  border-radius: 32px;
  background-color: ${props => props.color};
  border-color: transparent;
  margin-right: 2px;
  &:last-child {
    margin-right: 0;
  }
`;
