import { createSlice } from '@reduxjs/toolkit';
import {
  newDebt,
  getDebt,
  deleteDebt,
  editDebts,
  payDebt,
} from './debtOperations';

const initialState = {
  items: [],
  isLoading: false,
  error: null,
};

const handlePending = state => {
  state.isLoading = true;
};

const handleError = (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
};

const handleFulfilled = state => {
  state.isLoading = false;
  state.error = null;
};

export const DebtSlice = createSlice({
  name: 'debts',
  initialState,
  extraReducers: builder =>
    builder
      .addCase(newDebt.pending, handlePending)
      .addCase(newDebt.fulfilled, (state, action) => {
        handleFulfilled(state);
        state.items = [...state.items, action.payload];
      })
      .addCase(newDebt.rejected, handleError)
      .addCase(getDebt.pending, handlePending)
      .addCase(getDebt.fulfilled, (state, action) => {
        handleFulfilled(state);
        state.items = action.payload;
      })
      .addCase(getDebt.rejected, handleError)
      .addCase(deleteDebt.pending, handlePending)
      .addCase(deleteDebt.fulfilled, (state, action) => {
        handleFulfilled(state);
        state.items = state.items.filter(
          item => item._id !== action.payload.debtsId
        );
      })
      .addCase(deleteDebt.rejected, handleError)
      .addCase(editDebts.pending, handlePending)
      .addCase(editDebts.fulfilled, (state, action) => {
        handleFulfilled(state);
        const index = state.items.findIndex(
          item => item._id === action.payload._id
        );
        if (index !== -1) {
          state.items[index] = { ...state.items[index], ...action.payload };
        }
      })
      .addCase(editDebts.rejected, handleError)
      .addCase(payDebt.pending, handlePending)
      .addCase(payDebt.fulfilled, (state, action) => {
        handleFulfilled(state);
        const index = state.items.findIndex(
          item => item._id === action.payload._id
        );
        if (index !== -1) {
          state.items[index] = { ...state.items[index], ...action.payload };
        }
      })
      .addCase(payDebt.rejected, handleError),
});

export const debtsReducer = DebtSlice.reducer;
